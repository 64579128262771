import React from "react";
import { useEffect } from "react";

const Loader = ({ loading, text }) => {
  useEffect(() => {}, [loading]);

  return (
    loading && (
      <div className="loader-wrapper-div text-center">
        <p className="para mr-2">{text}</p>
        <div className="loader"></div>
      </div>
    )
  );
};

export default Loader;
