import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/atoms/jobs/Loader";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { useLanguage } from "../../languageContext";

const JobDescription = () => {
  const currentLanguage = useLanguage();
  const [jobDeatils, setJobDeatils] = useState({});
  const [position, setPosition] = useState("");
  const [loading, setLoading] = useState(false);
  const [accept, setAccept] = useState(false);
  const BASE_URL = "https://jobs.lever.co/v0/postings/gokenamerica1/";
  const fetchJobDetails = async (jobId) => {
    try {
      setLoading(true);
      const jobDetails1 = await axios.get(BASE_URL + jobId);
      console.log(jobDetails1);
      const positionText = jobDetails1.data["descriptionPlain"];
      setPosition(positionText);
      console.log(positionText);
      setJobDeatils(jobDetails1.data);
      setLoading(false);
    } catch (error) {
      window.location.replace("/404");
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.jobId) {
      fetchJobDetails(params.jobId);
    }
  }, []);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO title="Detailed Job Description " />
      <div className="detailed-job-description-wrapper">
        {/* {Object.keys(jobDeatils).length > 0 ? ( */}
        <div className="detailed-job-page-container w-90-wrapper">
          {Object.keys(jobDeatils).length > 0 ? (
            <div className="detailed-description-column-container">
              <div className="detailed-page-column-1">
                <div className="first-section-container">
                  <h2 className="para">Work With Us</h2>
                  <h1 className="normal-heading no-margin ">
                    {jobDeatils.text ? jobDeatils.text : ""}
                  </h1>
                  <p className="no-margin semi-title">
                    <h3 className="semi-title  margin-0">
                      <i>
                        {jobDeatils.categories
                          ? jobDeatils.categories.location
                            ? jobDeatils.categories.location
                            : ""
                          : ""}
                      </i>
                    </h3>
                  </p>
                  <div className="job-detail-card">
                    <p className="no-margin semi-para">Category</p>
                    <div className="dashed-text-container">
                      <div
                        className={`"dashed-color-${
                          currentLanguage ? currentLanguage : "en"
                        }`}
                      ></div>
                      <p className="no-margin bold para">
                        {jobDeatils.categories
                          ? jobDeatils.categories.team
                            ? jobDeatils.categories.team
                            : ""
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="apply-btn-container">
                    <button
                      className={`job-apply-now-btn btn-effect-${
                        currentLanguage ? currentLanguage : "en"
                      } color-${currentLanguage ? currentLanguage : "en"}`}
                      onClick={() => openInNewTab(jobDeatils.applyUrl)}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>

                <div className="second-section-container desktop-wrapper">
                  <h2 className="title no-margin">About Goken</h2>
                  <p className="para no-margin">{position}</p>
                </div>
              </div>

              <div className="detailed-page-column-2">
                {jobDeatils.lists
                  ? jobDeatils.lists.map((list) => {
                      return (
                        <div className="list-wrapper">
                          <div className="lists-section-1">
                            <h3 className="title no-margin">{list.text}</h3>
                            <ul
                              className="requirements-list para-text"
                              dangerouslySetInnerHTML={{ __html: list.content }}
                            ></ul>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="second-section-container mobile-wrapper">
                <h2 className="title no-margin">About Goken</h2>
                <p className="para-text no-margin">{position}</p>
              </div>
              <div className="apply-btn-container mobile-wrapper">
                <button
                  className={`job-apply-now-btn btn-effect-${
                    currentLanguage ? currentLanguage : "en"
                  } color-${currentLanguage ? currentLanguage : "en"}`}
                  onClick={() => openInNewTab(jobDeatils.applyUrl)}
                >
                  Apply Now
                </button>
              </div>
            </div>
          ) : (
            <Loader loading={loading} text={"Loading"} />
          )}
        </div>
        {/* ) : (
          <Loader text={"Getting Job Details"} />
        )} */}
      </div>
    </Layout>
  );
};

export default JobDescription;
